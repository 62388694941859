<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" day-date="true" />

    <v-row>
      <v-col md="4" class="d-flex">
        <h1>ALL PROJECTS</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" class="text-right">
        <v-btn color="blue-grey" dark> Reassign </v-btn>
      </v-col>
    </v-row>
    <datatable :headers="headersCarlos" :staticData="itemsCarlos"></datatable>

    <v-row>
      <v-col md="12" class="text-right">
        <v-btn color="blue-grey" dark> Reassign </v-btn>
      </v-col>
    </v-row>

    <datatable :headers="headersErise" :staticData="itemsErise"></datatable>

    <v-row>
      <v-col md="12" class="text-right">
        <v-btn color="blue-grey" dark> Reassign </v-btn>
      </v-col>
    </v-row>
    <datatable :headers="headersChello" :staticData="itemsChello"></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  name: "AllProjects",
  components: { CustomerInfo, Datatable },
  data() {
    return {
      headersCarlos: [
        { text: "Carlos", value: "carlos" },
        { text: "Bill Morrison", value: "morrison" },
        { text: "Gladstone", value: "gladstone" },
      ],
      itemsCarlos: [
        {
          carlos: "PIR",
          morrison: "Piers - 11 Ext",
          gladstone: "",
        },
        {
          carlos: "CRR ",
          morrison: "Crack Repair - 32 ft NP1",
          gladstone: "",
        },
        {
          carlos: "F+3/12",
          morrison: "Day 1 of 12",
          gladstone: "Drive",
        },
      ],
      headersErise: [
        { text: "Erise", value: "erise" },
        { text: "John Costanzo", value: "costanzo" },
        { text: "Kansas City", value: "kansasCity" },
      ],
      itemsErise: [
        {
          erise: "PIR",
          costanzo: "Piers - 11 Ext",
          kansasCity: "",
        },
        {
          erise: "F+3/4",
          costanzo: "Day 2 of 4",
          kansasCity: "Drive",
        },
      ],
      headersChello: [
        { text: "Chello", value: "chello" },
        { text: "Cassie Davis", value: "davis" },
        { text: "Grandview", value: "grandview" },
      ],
      itemsChello: [
        {
          chello: "EXD",
          davis: "Buried Discharge - 24 ft",
          grandview: "",
        },
        {
          chello: "CRR",
          davis: "Crack Repair -  40 ft epoxy",
          grandview: "",
        },
        {
          chello: "F+2/1",
          davis: "Day 1 of 1",
          grandview: "Drive",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
